import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Footer from '/components/marketing/footer/footer.js'
import Navbar from '/components/marketing/navbar/navbar.js'
import { fallbackLng } from '@/util/localization/i18n'

const Layout = ({ user, children }) => {
  const { _, i18n } = useTranslation()

  useEffect(() => {
    // sigma requested that the language selected during testing not apply to the marketing site (specifically the navbar)
    const currentLang = i18n.language
    i18n.changeLanguage(fallbackLng)
    return () => i18n.changeLanguage(currentLang)
  }, [])

  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
