import Head from 'next/head'
import Layout from '@/components/marketing/layout/layout'
import HeroBlock from '@/components/marketing/blocks/hero/hero-block.js'
import DemoBlock from '@/components/marketing/blocks/book-demo/demo-block.js'
import WhySigmaBlock from '@/components/marketing/blocks/why-sigma/why-sigma-block.js'
import { CookiePopup } from '@/components/cookie-popup'
import { sendGTMEvent } from 'util/gtm'
import {useEffect } from 'react'
import { useStore } from '@/util/store'

const Index = () => {
  const acceptedCookies = useStore((state) => state.acceptedCookies)

  useEffect(() => {
    if (acceptedCookies) {
      sendGTMEvent('allowed-analytics-location')
    }
  }, [acceptedCookies])
  
  return (
    <>
      <Head>
        <title>Sigma Assessments</title>
      </Head>
      <Layout>
        <HeroBlock />
        <WhySigmaBlock />
        <DemoBlock />
      </Layout>
      <CookiePopup days={1} />
    </>
  )
}

export default Index
