import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation('common')
  const copyrightDate = new Date().getFullYear()
  return (
    <footer className='footer marketing'>
      <div className='container footer-grid'>
        <img
          className='footer-logo'
          src='/images/marketing/sigma-logo.svg'
          alt='Sigma Logo'
        />
        <div className='footer-contact'>
          <a
            className='footer-phone'
            href='tel:18002651285'
            target='_blank'
            rel='nofollow noreferrer'
          >
            1.800.265.1285
          </a>
          <a
            className='footer-email'
            href='mailto:info@sigmaassessments.com'
            target='_blank'
            rel='nofollow noreferrer'
          >
            info@sigmaassessments.com
          </a>
        </div>
        <div className='footer-copyright'>
          <p>Copyright © {copyrightDate} SIGMA Assessments Systems, Inc.</p>
          <p>{t('allRightsReserved')}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
