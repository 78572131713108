export const TextInput = ({
  type = 'text',
  name,
  value,
  label,
  placeholder,
  variant,
  register,
  validationOptions,
  errors,
  errorText,
  ...props
}) => {
  const hasRegister = register ? register(name, validationOptions) : {}
  return (
    <div className={`input-component ${variant || ''}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={type}
        name={name}
        value={value}
        autoComplete='off'
        placeholder={placeholder}
        {...hasRegister}
        {...props}
      />
      {errors && errors[name] && <p className="field-hint is-error">{errorText}</p>}
    </div>
  )
}