import Button from 'components/marketing/button'
import Image from 'next/image'
import { sendGTMEvent } from 'util/gtm'

import styles from './why-sigma-block.module.scss'

const WhySigmaBlock = () => {
  return (
    <section className={styles.wrapper}>
      <div className={`${styles.container} container`}>
        <img
          className={styles.logo}
          src='/images/marketing/sigma-logo.svg'
          alt='Sigma logo'
        />
        <div className={styles['main-grid']}>
          <div className={styles['main-content']}>
            <h2 className={styles['main-title']}>Why SIGMA Assessments?</h2>
            <p className={styles['main-description']}>
              Since 1967, SIGMA has helped thousands of organizations across
              North America develop their people potential and increase
              organizational effectiveness.
            </p>
            <div className={styles['main-actions']}>
              <Button
                className={styles['demo-btn']}
                type='primary'
                text='Book a Demo'
                href='#book-demo'
                onClick={() => sendGTMEvent('about-demo-btn')}
              />

              <Button
                className={styles['learn-more']}
                type='text'
                text='Learn More'
                iconSrc='images/marketing/ic-arrow-filled.svg'
                href='https://www.sigmaassessmentsystems.com/'
                target='_blank'
              />
            </div>
          </div>
          <figure className={styles['main-image']}>
            <Image
              src='/images/marketing/woman-computer.png'
              layout='responsive'
              width={530}
              height={399}
            />
          </figure>
        </div>
        <div className={styles['columns']}>
          <div className={styles.column}>
            <h3 className={styles['column-title']}>
              Science-based assessments{' '}
            </h3>
            <p className={styles['column-description']}>
              We have spent over 50 years developing science-based assessment
              products and leadership coaching services.
            </p>
          </div>
          <div className={styles.column}>
            <h3 className={styles['column-title']}>
              Easy setup and administration
            </h3>
            <p className={styles['column-description']}>
              We bring simple, intuitive platforms and real-world applicability
              to our suite of assessments and coaching.
            </p>
          </div>
          <div className={styles.column}>
            <h3 className={styles['column-title']}>Valuable results & data</h3>
            <p className={styles['column-description']}>
              We’ve helped retail clients reduce employee terminations by 80%
              within the first 4 months and total turnover by 50% within the
              first year.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhySigmaBlock
