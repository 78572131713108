import { useState, useRef, useEffect } from 'react'

export const MultiSelect = ({
  name,
  label,
  placeholder,
  required,
  onChange,
  children,
  ...props
}) => {
  const [selected, setSelected] = useState(false)
  const [chosenValues, setChosenValues] = useState([])
  const ref = useRef()

  function handleOnChange(e) {
    if (e.target.selectedIndex === -1) return
    const label = e.target[e.target.selectedIndex].text
    const value = e.target.value
    const entryExists = chosenValues.find((o) => o.value == value)
    if (!entryExists && value !== 'placeholder')
      setChosenValues([...chosenValues, { value: value, label: label }])
    setSelected(true)
  }

  function removeFromChosenValues(removed) {
    const newValues = chosenValues.filter((value) => value.value !== removed)
    setChosenValues(newValues)
  }

  useEffect(() => {
    if (!chosenValues.length) {
      setSelected(false)
      ref.current.value = 'placeholder'
    }
    onChange(chosenValues.map((value, idx) => `${value.label}`).join(', '))
  }, [chosenValues, onChange])

  return (
    <div className='select-wrap multiselect-wrap'>
      {label && <label>{label}</label>}
      <div className='select multiselect'>
        <select
          {...props}
          onChange={(e) => handleOnChange(e)}
          onFocus={(e) => (e.target.selectedIndex = -1)}
          ref={ref}
        >
          {!selected && (
            <option default value='placeholder'>
              {placeholder}
            </option>
          )}
          {children}
        </select>
        <input
          type='hidden'
          name={name}
          value={chosenValues.map((value, idx) => `${value.label}`)}
        />
      </div>
      {chosenValues.length > 0 && (
        <div className='multi-select-display'>
          {chosenValues.map((value, idx) => (
            <div className='multi-select-options' key={idx}>
              {value.label}
              <div
                className='multi-select-remove'
                onClick={() => removeFromChosenValues(value.value)}
              ></div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
