import Button from 'components/marketing/button'
import Link from 'next/link'
import { sendGTMEvent } from 'util/gtm'
import styles from './hero-block.module.scss'

export default function HeroBlock(props) {
  return (
    <section className={styles.hero}>
      <figure>
        <img className={styles[`bg-image`]} src="/images/marketing/hero-bg.svg" />
      </figure>
      <div className={styles["bg-gradient"]} />

      <div className={`${styles["top-layout"]} container`}>
        <div className={styles["content-section"]}>
          <p className={styles.subhead}>OUR NEXT GEN ASSESSMENT PLATFORM</p>
          <h1 className={styles.title}>Welcome to SIGMA Assessments</h1>
          <p className={styles.description}>
          We offer HR professionals, business leaders, government agencies, psychologists, and counselors online access to our highly regarded assessment instruments.
          </p>

          <div className={styles["buttons-section"]}>
            <Button
              className={styles["demo-btn"]}
              type="primary"
              text="Book a Demo"
              href="#book-demo"
              onClick={() => sendGTMEvent('hero-demo-btn')}
            />

            <Button
              type="text"
              text="Learn More"
              iconSrc="images/marketing/ic-arrow-filled.svg"
              href="https://www.sigmaassessmentsystems.com/"
              target="_blank"
            />
          </div>
        </div>

        <img className={styles["hero-image"]} src="images/marketing/hero-image.png" />
      </div>

      <div className={`${styles.card} container`}>
        <div>
          <h3 className={styles["card-title"]}>Admin Log In</h3>
          <p className={styles["card-description"]}>
          Log in to SIGMA’s admin platform to administer, manage, and view assessment results.
          </p>
        </div>
        <Button 
          text="Admin Log In" 
          type="secondary" 
          href="/login" 
          target="_blank" 
          onClick={() => sendGTMEvent('hero-admin-login-btn')}
        />
      </div>

      <p className={`${styles["notification-card"]} container`}>
        <strong>Are you taking an assessment?</strong>{" "}
        <Link href="/tests"><a onClick={() => sendGTMEvent('hero-testtaker-link')} target="_blank">Click here to log in.</a></Link> You’ll need your access
        code from your test admin to enter.
      </p>
    </section>
  );
}
