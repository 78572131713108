import { useState } from 'react'
import { TextInput } from '@/components/marketing/text-input'
import { MultiSelect } from '@/components/marketing/multiselect'
import { useForm, Controller } from 'react-hook-form'
import classnames from '@/util/classnames'
import { sendGTMEvent } from 'util/gtm'
import settings from '@/util/settings'

import styles from './demo-block.module.scss'

const DemoBlock = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [formErrors, setFormErrors] = useState()

  const onSubmit = async (data, e) => {
    setFormSubmitting(true)
    sendGTMEvent('submit-demo-form')
    const res = await fetch(settings.apiUrl + '/api/demo-form', {
      method: 'POST',
      headers: { 
        'content-type': 'application/json'
     },
      body: JSON.stringify(data),
    })
    
    if (res.status === 201) {
      setFormSubmitted(true)
      setFormSubmitting(false)
    } else {
      setFormErrors(res.Message)
    }
  }
  const onError = (errors, e) => {
    setFormErrors(errors)
  }

  return (
    <section id='book-demo' className={styles.wrapper}>
      <div className={`${styles.container} container`}>
        <div className={styles.panels}>
          <div className={styles.panel}>
            <p className={styles['panel-title']}>Get in Touch</p>
            <h3 className={styles['panel-caption']}>
              Book a Demo and experience the convenience of online
              administration and instant reports.
            </h3>
          </div>
        </div>
        {!formSubmitted && (
          <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {formErrors && (
              <p
                className={classnames([
                  styles['form-error'],
                  'field-hint',
                  'is-error',
                ])}
              >
                There has been an error submitting the form. Please double-check
                that the fields have been filled out correctly, or try again
                later.
              </p>
            )}
            <div className='form-row'>
              <TextInput
                type='text'
                name='email'
                label='Email Address *'
                placeholder='hello@company.com'
                register={register}
                validationOptions={{ required: true, pattern: /^\S+@\S+$/i }}
                errors={errors}
                errorText='Please enter a valid email'
              />
            </div>
            <div className='form-row'>
              <TextInput
                name='firstName'
                label='First Name *'
                placeholder='First Name'
                register={register}
                errors={errors}
                validationOptions={{ required: true}}
                errorText='Please enter a first name'
              />
              <TextInput
                name='lastName'
                label='Last Name *'
                placeholder='Last Name'
                register={register}
                errors={errors}
                validationOptions={{ required: true}}
                errorText='Please enter a last name'
              />
            </div>
            <div className='form-row'>
              <TextInput
                name='company'
                label='Company *'
                placeholder='Company Name'
                register={register}
                errors={errors}
                validationOptions={{ required: true}}
                errorText='Please enter a company name'
              />
            </div>
            <div className='form-row'>
              <TextInput
                type='tel'
                name='phone'
                label='Phone Number'
                placeholder='Enter a Phone Number'
                register={register}
                validationOptions={{
                  minLength: 7,
                  maxLength: 12,
                  pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                }}
                errors={errors}
                errorText='Please enter a valid Phone number'
              />
            </div>
            <div className='form-row'>
              <Controller
                name='assessmentType'
                control={control}
                defaultValue={false}
                render={({ field: { onChange } }) => (
                  <MultiSelect
                    name='assessment-type'
                    label='Assessment types you’re interested in'
                    placeholder='Select One'
                    onChange={onChange}
                  >
                    <option value='leadership-development'>
                      Leadership Development
                    </option>
                    <option value='employee-selection'>
                      Employee Selection
                    </option>
                    <option value='career-tests'>Career Tests</option>
                    <option value='personality-tests'>Personality Tests</option>
                    <option value='clinical-assessments'>
                      Clinical/Counseling Assessments
                    </option>
                  </MultiSelect>
                )}
              />
            </div>
            <input
              type='submit'
              className={`btn btn-primary ${styles['form-submit']}`}
              value={formSubmitting ? 'Sending...' : 'Book a Demo'}
            />
          </form>
        )}
        {formSubmitted && (
          <h2 className={styles.success}>
            Thank you for your interest. We’ll be in touch shortly.
          </h2>
        )}
      </div>
    </section>
  )
}

export default DemoBlock
